const ENV_DEV = "https://dwclouddev.vestek.com.tr/api";
const ENV_TEST = "https://dwcloudtest.vestek.com.tr/api";
const ENV_PROD = "https://displaywizard.app/api";
const ENV_LOCAL = "http://192.168.9.106:5197/api";

const ENV_B2B_DEV = "https://b2bdev.vestek.com.tr/api";
const ENV_B2B_TEST = "https://b2blicencetest.vestek.com.tr/api";
const ENV_B2B_PROD = "https://cloudlicencemanager.com/api";
const ENV_B2B_LOCAL = "http://192.168.9.106:5162/api";

// For Display Wizard
const enviroments = {
  1: "http://192.168.1.41:5197/api",
  2: ENV_DEV,
  3: ENV_TEST,
  4: ENV_PROD,
};
const CURRENT_ENV = 4;

// For Licence B2B
const B2B_enviroments = {
  1: "http://192.168.9.101:5162/api",
  2: ENV_B2B_DEV,
  3: ENV_B2B_TEST,
  4: ENV_B2B_PROD,
};
const CURRENT_B2B_ENV = 4;

// Base URLs for Display wizard and Licence B2B are given in here:
export const API_BASE_URL = enviroments[CURRENT_ENV];
export const URL_B2B = B2B_enviroments[CURRENT_B2B_ENV]; //"https://b2blicencetest.vestek.com.tr/api";

export const URl_DEVICE = `${API_BASE_URL}/device`;
export const URl_DEVICE_ACTIVATE_NOTIFICATION = `${API_BASE_URL}/Device/ActivateNotification`;
export const URl_DEVICE_DEACTIVATE_NOTIFICATION = `${API_BASE_URL}/Device/InactiveNotification`;
export const URl_NOTIFICATIONS = `${API_BASE_URL}/NotificationCard`;
export const URl_COMPANY = `${API_BASE_URL}/company`;
export const URL_DASHBOARD = `${API_BASE_URL}/Dashboard`;
export const URL_GROUP = `${API_BASE_URL}/Group`;
export const URL_LOG = `${API_BASE_URL}/Log`;
export const URL_LOG_EXPORT = `${API_BASE_URL}/Log/LogExport`;
export const URL_SCREENSHOOT = `${URL_LOG}/screenshots`;
export const URL_DIAGNOSTIC = `${API_BASE_URL}/DiagnosticLog/Device`;
export const URL_CHANGE_PASSWORD_MANUALLY = `${API_BASE_URL}/User/ChangePasswordManually`;
export const URL_APPLICATIONS = `${API_BASE_URL}/Application`;
export const URL_SCHEDULESETTINGS = `${API_BASE_URL}/ScheduleSettings`;

// USER operations : Login, Sign Up, Password, User Porfile
export const LOGIN_URL = `${API_BASE_URL}/auth/login`;
export const URL_SIGNUP = `${API_BASE_URL}/auth/signup`;
export const URL_USER = `${API_BASE_URL}/user`;
export const URL_USER_PROFILE = `${URL_USER}/EditUserProfile`;
export const URL_USER_INFORMATION = `${URL_USER}/EditUserInformation`;
export const CHANGE_PASSWORD_URL = `${URL_USER}/changepassword`;
export const URL_PASSWORD_RESET_REQUEST = `${API_BASE_URL}/PasswordResetRequest/NewPasswordMail`;

// Licence B2B endpoints:
export const URL_LICENCE_RATE = `${URL_B2B}/licence/TotalLicenceRate`;
export const URL_LICENCE = `${URL_B2B}/licence/2`;

// Device Activate and Deactivate
export const URL_ACTIVATE_DEVICE = `${URl_DEVICE}/ActivateDevice`;
export const URL_DEACTIVATE_DEVICE = `${URl_DEVICE}/InactiveDevice`;

// Diagnostic Log Job START and STOP
export const URL_DIAGNOSTIC_LOG_JOB_START = `${API_BASE_URL}/DeviceDiagnosticLogJob/Start`;
export const URL_DIAGNOSTIC_LOG_JOB_STOP = `${API_BASE_URL}/DeviceDiagnosticLogJob/Stop`;
