import React, { useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
} from "configs/AppConfig";
import { ROUTE_PERMISSIONS } from "constants/AuthConstant";

const EXCLUDED_PATHS = ["/reset-password", "/forgot-password"];

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();

  const { permissions } = useSelector((state) => state.user);

  console.log(document.location.pathname, "path");

  // if (document.location.pathname === "/app/forgot-password") {
  //   return <Outlet />;
  // }
  console.log(location.pathname, "location");
  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${document.location.pathname}`}
        replace
      />
    );
  }

  // if (Object.keys(ROUTE_PERMISSIONS).includes(location.pathname) &&
  // 		!permissions.includes(ROUTE_PERMISSIONS[location.pathname])) {
  // 	return <Navigate to={'/app/dashboards/default'} />
  // }

  return <Outlet />;
};

export default ProtectedRoute;
