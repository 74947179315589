import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
  lastMessage: {},
  sendMessage: () => {},
};

export const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
      state.lastMessage = action.payload;
    },
    setSendMessage: (state, action) => {
      state.sendMessage = action.payload;
    }
  }
})

export const {
  addMessage,
  setSendMessage
} = webSocketSlice.actions;

export default webSocketSlice.reducer;